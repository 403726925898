<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :isLoadingSaveButton="isLoadingSaveButton"
  >
    <template #footer>
      <sm-button
        class="editable-item__form-button"
        :isLoading="isLoadingSaveButton"
        :disabled="isEqual"
        @click="onSave('TelemarketingMain')"
        >Создать</sm-button
      >
      <sm-button
        class="editable-item__form-button"
        outline
        neutrall
        @click="onCancel('TelemarketingMain')"
        >Отменить</sm-button
      >
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'TelemarketingTasksCreateMany',

  components: {
    SmEditableItem,
    SmButton,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingTasks',
      pageHeader: 'Множественное создание задания по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Множественное создание задания по телемаркетингу',
        },
      ],
      initialForm: {
        employeeId: null,
        roleName: null,
        regions: [],

        productId: null,
        scriptId: null,
      },
      form: null,
      isLoadingSaveButton: false,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
      organizationsRoles: (state) => state.common.organizationsRoles,
      organizationsRegions: (state) => state.common.organizationsRegions,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
      telemarketingScriptsForProduct: (state) =>
        state.common.telemarketingScriptsForProduct,
      telemarketingSkipCustomersCondition: (state) =>
        state.common.telemarketingSkipCustomersCondition,
    }),

    processedOrganizationsRoles() {
      let { data } = this.organizationsRoles;
      if (data.length) {
        return data.map((item) => ({
          ...item,
          value: item.name,
        }));
      }
      return [];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'employeeId',
              label: 'Исполнитель',
              list: this.employeeAccounts.data,
            },
            {
              type: 'select',
              key: 'roleName',
              label: 'Роль организации',
              list: this.processedOrganizationsRoles,
            },
            {
              type: 'multiSelect',
              key: 'regions',
              label: 'Регион организации',
              list: this.organizationsRegions.data,
            },
            {
              type: 'select',
              key: 'productId',
              label: 'Продукт телемаркетинга',
              list: this.telemarketingProducts.data,
            },
            {
              type: 'select',
              key: 'scriptId',
              label: 'Скрипт телемаркетинга',
              list: this.telemarketingScriptsForProduct.data?.map((item) => ({
                ...item,
                name: item.script,
              })),
            },
            { type: 'text', key: 'tags', label: 'Теги' },
            {
              type: 'checkbox',
              key: 'skipExistsCustomers',
              label: 'Пропускать действующих клиентов',
            },
            // {
            //   type: 'checkbox',
            //   key: 'SkipExistsTasks',
            //   label:
            //     'Созавать задания только для новых клиентов (то есть тех, где заданий не было)',
            // },
            {
              type: 'select',
              key: 'skipCustomers',
              label: 'Условие отбора клиентов',
              list: this.telemarketingSkipCustomersCondition.data,
            },
          ],
        },
      ];
    },
  },

  watch: {
    'form.productId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'TelemarketingScriptsForProduct',
            params: { productid: this.form.productId },
          });
        }
      },
    },
  },

  created() {
    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });
    const organizationsRoles = this.getCommonList({
      name: 'OrganizationsRoles',
    });
    const organizationsRegions = this.getCommonList({
      name: 'OrganizationsRegions',
    });
    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });
    const telemarketingSkipCustomersCondition = this.getCommonList({
      name: 'TelemarketingSkipCustomersCondition',
    });

    this.isLoadingPage = true;
    Promise.all([
      employeeAccounts,
      organizationsRoles,
      organizationsRegions,
      telemarketingProducts,
      telemarketingSkipCustomersCondition,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createManyTasks: 'telemarketing/createManyTasks',
      getOrganizationsRoles: 'telemarketing/getOrganizationsRoles',
      getOrganizationsRegions: 'telemarketing/getOrganizationsRegions',
      getCommonList: 'common/getCommonList',
    }),

    onSave(route) {
      this.isLoadingSaveButton = true;

      this.createManyTasks(this.form)
        .then((result) => {
          if (result.isSucceed) {
            this.$notify({
              header: `Создано ${result.id} заявок по телемаркетингу`,
              type: 'success',
              timer: 5000,
            });
            this.$router.push({ name: route });
          }
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },
  },
};
</script>
